import React from "react"
import { Styled } from "theme-ui"
import { Heading } from "@theme-ui/components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"

export default () => (
  <Layout>
    <Styled.root>
      <SEO title="Email sent successfully" />
      <Container pb={10} pt={5}>
        <Heading sx={{ mb: 4 }}>Email successfully sent</Heading>
        <Styled.p>
          We have received your message and we will contant you shortly.
        </Styled.p>
      </Container>
    </Styled.root>
  </Layout>
)
